<template>
  <div>
    <div class="modal_header">
      <el-row>
        <el-col :xs="24" :sm="15" :md="15" :lg="15" :xl="15">
          <div class="grid-content bg-purple">
            <div class="title_h">
              <h5>{{ $t("message.services") }}</h5>
            </div>
          </div>
        </el-col>
        <!-- end col -->

        <el-col :xs="24" :sm="9" :md="9" :lg="9" :xl="9">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button type="success" :loading="loadingButton" :disabled="loadingButton ? true : false"
                @click="submit(true)">{{ $t("message.save_and_close") }}</el-button>
              <el-button @click="close()">{{ $t("message.close") }}</el-button>
            </el-row>
          </div>
        </el-col>
        <!-- end col -->
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-row class="p20">
            <el-tabs class="theePopup" v-model="activeTab" v-loading="loadingData" @tab-click="tabEvent">
              <el-col :xs="24" :sm="15" :md="15" :lg="15" :xl="15">
                <el-select v-model="patientServiceId" filterable :placeholder="$t('message.start_input_find_service')"
                  class="autoInput" clearable>
                  <template v-for="serviceParent in servicesSelected">
                    <el-option-group v-if="serviceParent.children.length > 0" :key="serviceParent.id"
                      :label="serviceParent.name">
                      <el-option v-for="service in getChildrens(serviceParent.children)" :key="service.id"
                        :label="service.name" :value="service.id">
                      </el-option>
                    </el-option-group>
                    <el-option-group v-else-if="serviceParent.is_service == true &&
                      serviceParent.parent_id == null
                      " :key="serviceParent.id">
                      <el-option :label="serviceParent.name" :value="serviceParent.id">
                      </el-option>
                    </el-option-group>
                  </template>
                </el-select>
              </el-col>
              <!-- end col -->

              <el-col :xs="24" :sm="9" :md="9" :lg="9" :xl="9">
                <el-button  style="font-size: 14px !important; padding: 12px !important"
                  @click="addService">{{ $t("message.add") }}</el-button>
                <el-button style="font-size: 14px !important; padding: 12px !important"
                  @click="drawerPatientService = true">{{ $t("message.add_service") }}</el-button>
                <el-button v-if="contractServiceList.length != 0 && form.contract_id"
                  style="font-size: 14px !important; padding: 12px !important" @click="AddContactServices">{{
                    $t("message.subject_of_agreement") }}</el-button>
              </el-col>
              <!-- end col -->

              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <table class="table table-striped table-bordered mt-4">
                  <thead>
                    <tr>
                      <th>{{ $t("message.pp") }}</th>
                      <th class="wi-50">{{ $t("message.name") }}</th>
                      <th class="wi-7">{{ $t("message.quantity_short") }}</th>
                      <th class="wi-7">{{ $t("message.price2") }}</th>
                      <th class="wi-7">{{ $t("message.discount_percent") }}</th>
                      <th class="wi-7">{{ $t("message.discount_price") }}</th>
                      <th class="wi-7">{{ $t("message.price2") }}</th>
                      <th class="wi-7">{{ $t("message.delete") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(ser, index) in list" :key="'order-create-in-header' + index">
                      <td>{{ index + 1 }}</td>
                      <td>
                        {{ ser.name }}
                      </td>
                      <td>
                        <el-input type="number" id="usr" size="small" step="any" @input="editRow(ser, $event, 'count')"
                          :value="ser.count" :min="1"></el-input>
                      </td>
                      <td>
                        {{ ser.price | formatMoney }}
                      </td>

                      <td>
                        <el-input-number :disabled="disableDiscount()" type="number" size="small"
                          @change="editRow(ser, $event, 'discount_price')" controls-position="right"
                          :value="discountService(ser.discount_price)" :min="0" :max="100"></el-input-number>
                      </td>
                      <td>
                        {{
                          Math.round(
                            (discountService(ser.discount_price) * ser.price) /
                            100
                          ) | formatMoney
                        }}
                      </td>
                      <td>
                        {{
                          Math.round(
                            ser.count *
                            (ser.price -
                              (ser.price *
                                discountService(ser.discount_price)) /
                              100)
                          ) | formatMoney
                        }}
                      </td>
                      <td>
                        <el-button class="d-block" type="danger" icon="el-icon-delete" circle
                          @click.native.prevent="deleteRow(ser)">
                        </el-button>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <th colspan="5">{{ $t("message.total_0") }}</th>
                    <th class="w-7">{{ totalDiscount | formatMoney }}</th>
                    <th colspan="2">
                      {{ Math.round(totalAmount) | formatMoney }}
                    </th>
                  </tfoot>
                </table>
              </el-col>
            </el-tabs>
          </el-row>
        </el-col>
      </el-row>
    </div>

    <el-drawer class="popups_title" :wrapperClosable="false" :append-to-body="true" :visible.sync="drawerPatientService"
      direction="rtl" size="70%">
      <CreatePatientService :driver="drawerPatientService" @c-close="closeDrawerPatientService"></CreatePatientService>
    </el-drawer>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import users from "@/components/selects/user_list";
import order from "@/utils/mixins/order";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";

export default {
  props: ["patient"],

  mixins: [order, drawer, list],
  components: {
    users,
  },
  data() {
    return {
      //   serviceParent: [],
    };
  },
  computed: {
    ...mapGetters({
      contractServiceList: "contracts/contract_services",
      // model: "orders/relation_model",
      workTimes: "workTimes/inventory",
    }),
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions({
      setworkTimes: "workTimes/inventory",
      editPatient: "patients/show",
      editService: "services/show",
      servicesRealtionList: "services/relationList",
      contractServices: "contracts/contractServices",
      discountPatient: "discountPatients/show",
      showModel: "conclusions/show",
      save: "orders/store",
      update: "orders/update",
      saveList: "services/setlist",
    }),

    fetchData() {
      this.loadingData = true;
      const query = { relation: true };
      this.servicesRealtionList(query)
        .then((res) => {
          this.loadingData = false;
        })
        .catch((err) => { });
    },
    disableDiscount() {
      if (
        this.discountPatients.length &&
        this.discountPatients[0].discount != 0 &&
        this.discountPatients[0].status
      ) {
        return true;
      } else return false;
    },
    discountService(sevice_discount) {
      if (
        this.discountPatients.length &&
        this.discountPatients[0].discount != 0 &&
        this.discountPatients[0].status
      ) {
        this.list.forEach((element) => {
          element.discount_price = this.discountPatients[0].discount;
        });
        return this.discountPatients[0].discount;
      } else return sevice_discount;
    },
    submit(close) {
      this.changeWaiting(true);
      this.form.patient_id = this.patient.patient.id;
      this.form.services = this.list;
      this.form.condition_id = this.patient.condition.id;
      this.form.day = this.patient.day;
      this.form.doctor_id = this.patient.doctor_id;
      this.form.duration = this.patient.duration;
      this.form.from_hour = this.patient.from_hour;
      this.form.notes = this.patient.notes;
      this.form.partner_doctor_id = this.patient.partner_doctor;


      this.save(this.form)
        .then((res) => {
          this.old_save = true;
          this.old_order = res.data.order;
          this.changeWaiting();
          this.saveBtn = false;
          this.printModel = res.data ? res.data.order : null;
          this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
        })
        .catch((err) => {
          this.changeWaiting();
          this.ifValidationError(err);
        });
    },
    tabEvent(event) {
      if (
        event.name == "second" &&
        this.form.contract_id &&
        this.contractServiceList.length == 0
      ) {
        this.contractServices({
          id: this.form.contract_id,
          contract_service: true,
        })
          .then((res) => { })
          .catch((err) => { });
      }
    },
  },
  mounted() {
    console.log(this.patient);
  },
};
</script>